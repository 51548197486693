import React, { useEffect, useState } from 'react';
import './App.css';
import WrappedMap from './components/gMap/location';
import config from './components/gMap/config';
import useFetch from './hooks/useFetch';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

const headers = {
  'x-api-key': "jyn79IdfAM8qPzf5gT7Ix7q3hABfYfkQ5OjHVWBb",
  "ACCESS-CONTROL-ALLOW-ORIGIN": "*",
  "ACCESS-CONTROL-ALLOW-CREDENTIALS": "TRUE",
  "ACCESS-CONTROL-ALLOW-HEADERS": "ORIGIN, X-REQUESTED-WITH, CONTENT-TYPE, ACCEPT, AUTHORIZATION",
  "Origin": "https://fbgo.in/"
};

// const encryptServiceMapId = (decryptedId) => {
//   const digitToAlphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'];
//   const digits = decryptedId.toString().split('');
//   const encryptedId = digits.map(digit => digitToAlphabet[parseInt(digit, 10)]).join('');
//   return encryptedId;
// };

// const decryptServiceMapId = (encryptedId) => {
//   const alphabetToDigit = {
//     'a': 0, 'b': 1, 'c': 2, 'd': 3, 'e': 4,
//     'f': 5, 'g': 6, 'h': 7, 'i': 8, 'j': 9
//   };
//   const alphabets = encryptedId.split('');
//   const decryptedId = alphabets.map(alphabet => alphabetToDigit[alphabet]).join('');
//   return parseInt(decryptedId, 10);
// };

// const encryptWord = (word) => {
//   const letterToSymbol = {
//     'a': 'k', 'b': 'l', 'c': 'm', 'd': 'n', 'e': 'o', 'f': 'p', 'g': 'q', 'h': 'r', 'i': 's', 'j': 't',
//     'k': 'u', 'l': 'v', 'm': 'w', 'n': 'x', 'o': 'y', 'p': 'z', 'q': 'a', 'r': 'b', 's': 'c', 't': 'd',
//     'u': 'e', 'v': 'f', 'w': 'g', 'x': 'h', 'y': 'i', 'z': 'j'
//   };
//   const letters = word.toLowerCase().split('');
//   const encryptedWord = letters.map(letter => letterToSymbol[letter] || letter).join('');
//   return encryptedWord;
// };

// const decryptWord = (encryptedWord) => {
//   const symbolToLetter = {
//     'k': 'a', 'l': 'b', 'm': 'c', 'n': 'd', 'o': 'e', 'p': 'f', 'q': 'g', 'r': 'h', 's': 'i', 't': 'j',
//     'u': 'k', 'v': 'l', 'w': 'm', 'x': 'n', 'y': 'o', 'z': 'p', 'a': 'q', 'b': 'r', 'c': 's', 'd': 't',
//     'e': 'u', 'f': 'v', 'g': 'w', 'h': 'x', 'i': 'y', 'j': 'z'
//   };
//   const symbols = encryptedWord.split('');
//   const decryptedWord = symbols.map(symbol => symbolToLetter[symbol] || symbol).join('');
//   return decryptedWord;
// };

//'x-api-key': 'qoolYDWAZk35GBRdyx9dm7dwKFGTYLh67airnscP',

const fetchStopLocation = async (stopId) => {
  const url = 'https://69ij7aeyrl.execute-api.ap-south-1.amazonaws.com/test/boarding-point';
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        "path": stopId
      }),
      headers: {
        "Content-Type": 'application/json',
      }
    });
    if (response.status === 403) {
      console.error("Access denied. Check your API permissions and API key.");
      return null;
    } else {
      const result = await response.json();
      if (result.statusCode === 301) {
        return result.headers['Location']; 
      } else {
        console.error("Redirect failed, stop not found or error occurred.");
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching Location:", error);
    return null;
  }
};

const fetchGoogle360Link = async (stopId) => {
  const url = 'https://69ij7aeyrl.execute-api.ap-south-1.amazonaws.com/test/boarding-point'; 
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        "path": stopId
      }),
      headers: {
        "Content-Type": 'application/json',
      }
    });
    if (response.status === 403) {
      console.error("Access denied. Check your API permissions and API key.");
      return null;
    } else {
      const result = await response.json();
      if (result.statusCode === 301) {
        return result.headers['Google-360-Link']; 
      } else {
        console.error("Redirect failed, stop not found or error occurred.");
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching Google 360 link:", error);
    return null;
  }
};

function App() {
  const { data: stops } = useFetch('https://61a4a0604c822c0017041d33.mockapi.io/shuttle/v1/stops');
  const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${config.mapsKey}`;

  const [paths, setPaths] = useState([{ lng: 79.203735, lat: 13.497608 }]);

  const getData = (serviceMapId, destId) => {
      // const params = new URLSearchParams(document.location.search);
    // const key = params.keys().next().value; 
    // const serviceMapId = params.get(key);
    // const encryptedKey = params.keys().next().value; 
    // const decryptedKey = decryptWord(encryptedKey); 
    // const encryptedServiceMapId = params.get(encryptedKey);
      
    if (serviceMapId) {
      fetch(`https://api.freshbus.com/gps_data?id=${serviceMapId}`, {
        method: 'GET',
        headers: headers,
      })
        .then(response => response.json())
        .then(data => {
          if (data.latitude) {
            const stopData = data.BP.find(stop => stop.stop_id === destId);
            if(stopData){
                if (stopData.actual_timeofarrival){
                    stopData.eta = 'Crossed Stop'; 
                } else {
                    const hours = Math.floor(stopData.eta / 60);
                    const mins = stopData.eta % 60;
                    stopData.eta = `${hours}h ${mins}m`;
                }    
            }
            setPaths([{
              lng: parseFloat(data.longitude),
              lat: parseFloat(data.latitude),
              sern: data.serviceNumber,
              drin: data.driverName,
              drinum: data.driverNumber,
              ss: data.serviceStartTime,
              se: data.serviceEndTime,
              veh: data.vehicleNumber,
              jd: data.journeyDate,
              routeName: data.routeName,
              refresh: new Date(data.refreshedAt * 1000).toLocaleTimeString(),
              soc: data.SOC,
              bp: stopData ? stopData.stop_name : 'No Boarding Key',
              eta: stopData ? stopData.eta : 'N/A'
            }]);
          }
        })
        .catch(error => console.log(error));
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    let serviceMapId = params.get('id');
    let stopId = params.get('sd');
    let destId = 0;
    //const key = params.keys().next().value; 
    //const encryptedKey = params.keys().next().value; 
    //const decryptedKey = decryptWord(encryptedKey); 
    //const serviceMapId = params.get(key);
    //const stopId = params.get('stopid');

    if (!serviceMapId) {
      const pathSegments = document.location.pathname.split('/');
      
      serviceMapId = pathSegments[pathSegments.length - 1];
    //   const tempSegment = pathSegments.find(segment => segment.includes('&'));
    //   console.log(serviceMapId);
    //   if (serviceMapId.includes('&')) {
    //     const [sIdpart, dIdpart] = serviceMapId.split('&');
    //     serviceMapId = parseInt(sIdpart);
    //     destId = parseInt(dIdpart);
    //     console.log(serviceMapId + destId)
    //   }


      const stopSegment = pathSegments.find(segment => segment.startsWith('s_'));
      if (stopSegment) {
        stopId = stopSegment.split('_')[1];
        fetchStopLocation(stopId).then(location => {
          // Do something with the location, for example, update state
          if (location) {
              window.location.href=location;
          }
          else {
              document.body.innerHTML = '<h1>Link is unavailable, please contact FreshBus customer care for further details.</h1>';
          }
        });
        return;
      } else {
        const google360Segment = pathSegments.find(segment => segment.startsWith('d_'));
        if (google360Segment) {
          stopId = google360Segment.split('_')[1];
          fetchGoogle360Link(stopId).then(link => {
            if (link) {
              window.location.href = link; // Redirect to the actual link
            }
            else {
              document.body.innerHTML = '<h1>Link is unavailable, please contact FreshBus customer care for further details.</h1>';
            }
          });
          return;
        }
      }
    }
    
    if(stopId) {
      if (stopId.startsWith("s_")) {
        stopId = stopId.split('_')[1];
        fetchStopLocation(stopId).then(location => {
          // Do something with the location, for example, update state
          if (location) {
              window.location.href=location;
          }
          else {
              document.body.innerHTML = '<h1>Link is unavailable, please contact FreshBus customer care for further details.</h1>';
          }
        });
        return;
      } else {
        if (stopId.startsWith("d_")) {
          stopId = stopId.split('_')[1];
          fetchGoogle360Link(stopId).then(link => {
            if (link) {
              window.location.href = link; // Redirect to the actual link
            }
            else {
              document.body.innerHTML = '<h1>Link is unavailable, please contact FreshBus customer care for further details.</h1>';
            }
          });
          return;
        }
      }
    }

    if (serviceMapId) {
      const [sIdpart, dIdpart] = serviceMapId.split('&');
      serviceMapId = parseInt(sIdpart);
      destId = parseInt(dIdpart);
      const intervalCall = setInterval(() => getData(serviceMapId, destId), 3000);
      return () => clearInterval(intervalCall);
    }
  }, []);
    
  return (
  <div className="App">
    <div className="header">
      <img className='logoImage' src='logos.jpg' alt="logo" />
    </div>
    <div className="mapBody">
      {paths && stops ?
        <WrappedMap
          paths={paths}
          stops={stops}
          googleMapURL={mapURL}
          loadingElement={<div style={{ height: '90%' }} />}
          containerElement={<div className='mapContainer' />}
          mapElement={<div style={{ height: '90%' }} />}
        /> :
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      }
    </div>
    <div className="metadata">
      <span className="s1">
        <p>Starting At</p>
        <p className="s3">{paths[0]?.ss}</p>
      </span>
      <span className="route">
        <p>Route</p>
        <p className="s3">{paths[0]?.routeName}</p>
      </span>
      <span className="s2">
        <p>Reaching At</p>
        <p className="s3">{paths[0]?.se}</p>
      </span>
    </div>
    <div className="metadata">
      <span className="s1">
        <p>Stop Name</p>
        <p className="s3">{paths[0]?.bp}</p>
      </span>
      <span className="s2">
        <p>ETA</p>
        <p className="s3">{paths[0]?.eta}</p>
      </span>
    </div>
    <div className="metadata">
      <span className="s1">
        <p>ServiceNumber</p>
        <p className="s3">{paths[0]?.sern}</p>
      </span>
      <span className="s2">
        <p>Journey Date</p>
        <p className="s3">{paths[0]?.jd}</p>
      </span>
    </div>
    <div className="metadata">
      <span className="s1">
        <p>DriverName</p>
        <p className="s3">{paths[0]?.drin}</p>
      </span>
      <span className="s2">
        <p>VehicleNumber</p>
        <p className="s3">{paths[0]?.veh}</p>
      </span>
    </div>
    <div className="metadata">
      <span className="s1">
        <p>Last Updated</p>
        <p className="s3">{paths[0]?.refresh}</p>
      </span>
    </div>
    <div className="data">
      <PhoneOutlinedIcon style={{ color: '#FAE823' }} />&nbsp;&nbsp;
      <a className="data2 s3" href={`tel:${paths[0]?.drinum}`}> +91 {paths[0]?.drinum}</a>
    </div>
  </div>
);
}


export default App;
